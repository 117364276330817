import React, { useEffect, useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { addDoc, collection, doc, onSnapshot } from "firebase/firestore";
import { storage, firestore, query, where } from "../../firebase";
import { Button } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

import { setAdminShowJob, setShowJob } from "../../redux/uiSlice";
import { v4 } from "uuid";
import { updateJob } from "../../redux/jobsSlice";

const ApplicationForm = () => {
  const dispatch = useDispatch();

  const [allJobs, setAllJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedJob = useSelector((state) => state.allJobs.selectedJob);
  const adminShowJob = useSelector((state) => state.ui.adminShowJob);

  const [jobData, setJobData] = useState({
    jobId: "",
    image: "",
    location: "",
    title: "",
    subTitle: "",
    totalExperience: "",
    relevantExperience: "",
    salary: "",
    city: "",
    state: "",
    postedAt: "",
    openings: "",
    applicants: "",
    jobDescription: "",
    requiredSkills: "",
    roleName: "",
    industryType: "",
    department: "",
    employmentType: "",
    roleCategory: "",
    education: "",
    keySkills: "",
  });
  useEffect(() => {
    setJobData({
      jobId: selectedJob?.jobId,
      image: selectedJob?.image,
      location: selectedJob?.location,
      title: selectedJob?.title,
      subTitle: selectedJob?.subTitle,
      totalExperience: selectedJob?.totalExperience,
      relevantExperience: selectedJob?.relevantExperience,
      salary: selectedJob?.salary,
      city: selectedJob?.city,
      state: selectedJob?.state,
      postedAt: selectedJob?.postedAt,
      openings: selectedJob?.openings,
      applicants: selectedJob?.applicants,
      jobDescription: selectedJob?.jobDescription,
      requiredSkills: selectedJob?.requiredSkills,
      roleName: selectedJob?.roleName,
      industryType: selectedJob?.industryType,
      department: selectedJob?.department,
      employmentType: selectedJob?.employmentType,
      roleCategory: selectedJob?.roleCategory,
      education: selectedJob?.education,
      keySkills: selectedJob?.keySkills,
    });
  }, []);

  const jobs = collection(firestore, "jobs");

  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    if (name === "keySkills") {
      setJobData({ ...jobData, [name]: value.split(",") });
    } else {
      setJobData({ ...jobData, [name]: value });
    }
  };
  const styleObj = {
    border: "2px solid #ced4da",
    borderRadius: "2px",
    outline: "none",
    fontSize: "15px",
  };

  const submitData = async (event) => {
    event.preventDefault();

    if (!selectedJob?.id) {
      addDoc(jobs, jobData).then(() => {
        alert("Job Added");
        setJobData({
          jobId: "",
          image: "",
          location: "",
          title: "",
          subTitle: "",
          totalExperience: "",
          relevantExperience: "",
          salary: "",
          city: "",
          state: "",
          postedAt: "",
          openings: "",
          applicants: "",
          jobDescription: "",
          requiredSkills: "",
          roleName: "",
          industryType: "",
          department: "",
          employmentType: "",
          roleCategory: "",
          education: "",
          keySkills: "",
        });

        dispatch(setAdminShowJob({ window: false, form: false }));
      });
    } else {
      dispatch(updateJob({ jobId: selectedJob.id, newJobData: jobData }));
      dispatch(setAdminShowJob({ window: false, form: false }));
    }
  };

  return (
    <>
      {/* Start Single Timeline  */}
      <div className="rn-timeline-wrapper timeline-style-one position-relative">
        <div className="single-timeline ">
          <div className="single-content">
            <div className="inner">
              <div className="row row--30 align-items-center">
                <div className="order-1 p-sm-5  col-lg-12 ">
                  <div className="thumbnail ">
                    <div className="rtl">
                      <div
                        className="card"
                        style={{ maxHeight: "550px", overflowY: "scroll" }}
                      >
                        <div className="card-body">
                          <div style={{ minHeight: "20px" }}></div>
                          <div className="d-flex justify-content-between">
                            <h4 className="card-title d-flex justify-content-center">
                              Add Job
                            </h4>

                            {adminShowJob.job?.jobId && (
                              <Button
                                onClick={() =>
                                  dispatch(setAdminShowJob({ form: false }))
                                }
                                style={{ fontSize: "15px" }}
                              >
                                View Description
                              </Button>
                            )}
                          </div>

                          <h7 className="card-title d-flex pt-0 justify-content-center">
                            Please Fill Out the Form Below to Add job Posting!
                          </h7>
                          <div style={{ minHeight: "20px" }}></div>
                          <form onSubmit={submitData}>
                            <div className="row mt-4">
                              <div className="col-md-6 ">
                                <label htmlFor="validationCustom01">
                                  Job Id
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="jobId"
                                  value={jobData.jobId}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                />
                              </div>
                              <div className="col-md-6 ">
                                <label htmlFor="validationCustom04">
                                  Image
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="image"
                                  value={jobData.image}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                  placeholder={adminShowJob.job?.image}
                                  defaultValue={adminShowJob.job?.image}
                                />
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-6 ">
                                <label htmlFor="validationCustom01">
                                  Location
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="location"
                                  value={jobData.location}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                  placeholder={adminShowJob.job?.location}
                                  defaultValue={adminShowJob.job?.location}
                                />
                              </div>
                              <div className="col-md-6 ">
                                <label htmlFor="validationCustom04">
                                  Title
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="title"
                                  value={jobData.title}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                  placeholder={adminShowJob.job?.title}
                                  defaultValue={adminShowJob.job?.title}
                                />
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col-md-6 ">
                                <label htmlFor="validationCustom01">
                                  Sub Title
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="subTitle"
                                  value={jobData.subTitle}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                  placeholder={adminShowJob.job?.subTitle}
                                  defaultValue={adminShowJob.job?.subTitle}
                                />
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-6">
                                <label htmlFor="validationCustom04">
                                  Total Experience
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="totalExperience"
                                  value={jobData.totalExperience}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                  placeholder={
                                    adminShowJob.job?.totalExperience
                                  }
                                  defaultValue={
                                    adminShowJob.job?.totalExperience
                                  }
                                />
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="validationCustom04">
                                  Relevant Experience
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="relevantExperience"
                                  value={jobData.relevantExperience}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                  placeholder={
                                    adminShowJob.job?.relevantExperience
                                  }
                                  defaultValue={
                                    adminShowJob.job?.relevantExperience
                                  }
                                />
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-6">
                                <label htmlFor="validationCustom04">
                                  Salary
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="salary"
                                  value={jobData.salary}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                />
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-6">
                                <label htmlFor="validationCustom04">City</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="city"
                                  value={jobData.city}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                  placeholder={adminShowJob.job?.city}
                                  defaultValue={adminShowJob.job?.city}
                                />
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="validationCustom04">
                                  State
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="state"
                                  value={jobData.state}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                  placeholder={adminShowJob.job?.state}
                                  defaultValue={adminShowJob.job?.state}
                                />
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-6">
                                <label htmlFor="validationCustom04">
                                  Posted At
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="postedAt"
                                  value={jobData.postedAt}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                  placeholder={adminShowJob.job?.postedAt}
                                  defaultValue={adminShowJob.job?.postedAt}
                                />
                              </div>
                            </div>
                            <div style={{ minHeight: "50px" }}></div>

                            <div className="row mt-4">
                              <div className="col-md-6 ">
                                <label htmlFor="validationCustom01">
                                  Openings
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="openings"
                                  value={jobData.openings}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                  placeholder={adminShowJob.job?.openings}
                                  defaultValue={adminShowJob.job?.openings}
                                />
                              </div>
                              <div className="col-md-6 ">
                                <label htmlFor="validationCustom04">
                                  Applicants
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="applicants"
                                  value={jobData.applicants}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                  placeholder={adminShowJob.job?.applicants}
                                  defaultValue={adminShowJob.job?.applicants}
                                />
                              </div>
                            </div>
                            <div style={{ minHeight: "50px" }}></div>
                            <div className="row mt-4">
                              <div className="col-md-12 ">
                                <label htmlFor="validationCustom01">
                                  Job Description
                                </label>
                                <textarea
                                  className="form-control"
                                  style={{
                                    fontSize: "15px",
                                    height: "100px",
                                    border: "2px solid #ced4da",
                                    borderRadius: "2px",
                                    outline: "none",
                                  }}
                                  name="jobDescription"
                                  value={jobData.jobDescription}
                                  onChange={postUserData}
                                  required
                                  placeholder={adminShowJob.job?.jobDescription}
                                  defaultValue={
                                    adminShowJob.job?.jobDescription
                                  }
                                />
                              </div>
                              <div className="col-md-12 ">
                                <label htmlFor="validationCustom04">
                                  Required Skills
                                </label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  name="requiredSkills"
                                  value={jobData.requiredSkills}
                                  onChange={postUserData}
                                  style={{
                                    fontSize: "15px",
                                    minHeight: "70px",
                                    border: "2px solid #ced4da",
                                    borderRadius: "2px",
                                    outline: "none",
                                  }}
                                  required
                                  placeholder={adminShowJob.job?.requiredSkills}
                                  defaultValue={
                                    adminShowJob.job?.requiredSkills
                                  }
                                />
                              </div>
                            </div>
                            <div style={{ minHeight: "50px" }}></div>
                            <div className="row mt-4">
                              <div className="col-md-12">
                                <label htmlFor="validationCustom04">
                                  Role Name
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="roleName"
                                  value={jobData.roleName}
                                  onChange={postUserData}
                                  style={{
                                    fontSize: "15px",

                                    border: "2px solid #ced4da",
                                    borderRadius: "2px",
                                    outline: "none",
                                  }}
                                  required
                                  placeholder={adminShowJob.job?.roleName}
                                  defaultValue={adminShowJob.job?.roleName}
                                />
                              </div>
                            </div>
                            <div style={{ minHeight: "10px" }}></div>
                            {/* <div className="row mt-4">
                              <div className="col-md-6">
                                <div class="custom-file">
                                  <label
                                    class="custom-file-label"
                                    for="inputGroupFile04"
                                  >
                                    Upload Resume
                                  </label>
                                  <input
                                    type="file"
                                    accept=".pdf"
                                    onChange={(event) => {
                                      setFileUpload(event.target.files[0]);
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                            </div> */}
                            <div className="row mt-4">
                              <div className="col-md-6 ">
                                <label htmlFor="validationCustom01">
                                  Industry Type
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="industryType"
                                  value={jobData.industryType}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                  placeholder={adminShowJob.job?.industryType}
                                  defaultValue={adminShowJob.job?.industryType}
                                />
                              </div>
                              <div className="col-md-6 ">
                                <label htmlFor="validationCustom04">
                                  Department
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="department"
                                  value={jobData.department}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                  placeholder={adminShowJob.job?.department}
                                  defaultValue={adminShowJob.job?.department}
                                />
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-6 ">
                                <label htmlFor="validationCustom01">
                                  Openings
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="employmentType"
                                  value={jobData.employmentType}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                  placeholder={adminShowJob.job?.employmentType}
                                  defaultValue={
                                    adminShowJob.job?.employmentType
                                  }
                                />
                              </div>
                              <div className="col-md-6 ">
                                <label htmlFor="validationCustom04">
                                  Role Category
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="roleCategory"
                                  value={jobData.roleCategory}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                  placeholder={adminShowJob.job?.roleCategory}
                                  defaultValue={adminShowJob.job?.roleCategory}
                                />
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-6 ">
                                <label htmlFor="validationCustom01">
                                  Education
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="education"
                                  value={jobData.education}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                  placeholder={adminShowJob.job?.education}
                                  defaultValue={adminShowJob.job?.education}
                                />
                              </div>
                              <div className="col-md-6 ">
                                <label htmlFor="validationCustom04">
                                  Key Skills
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="keySkills"
                                  value={jobData.keySkills}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                  placeholder={adminShowJob.job?.keySkills}
                                  defaultValue={adminShowJob.job?.keySkills}
                                />
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-12">
                                <div class="d-grid gap-2">
                                  <button
                                    class="btn btn-primary btn-lg"
                                    type="submit"
                                  >
                                    Add Job
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Single Timeline  */}
    </>
  );
};

export default ApplicationForm;
