import Modal from "react-bootstrap/Modal";

import { useSelector, useDispatch } from "react-redux";
import { setShowJob } from "../../redux/uiSlice";
import Description from "./JobDescription";
import ApplicationForm from "./ApplicationForm";
import { setSelectedJob } from "../../redux/jobsSlice";

function JobDescriptionModal() {
  const dispatch = useDispatch();
  const showJob = useSelector((state) => state.ui.showJob);

  return (
    <>
      <Modal
        size="xl"
        show={showJob.window}
        fullscreen={true}
        onHide={() => {
          dispatch(setSelectedJob(null));
          dispatch(setShowJob({ window: false, form: false }));
        }}
      >
        {showJob.form ? <ApplicationForm /> : <Description />}
      </Modal>
    </>
  );
}

export default JobDescriptionModal;
