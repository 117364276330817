const dummyJobs = [
  {
    jobId: "JOB001",
    image: "team-01",
    location: "Pune",
    title: "Looking For React JS Developer (WFO - Pune)",
    subTitle: "freshers candidates Only",
    totalExperience: "Experience 3 to 5 years",
    relevantExperience: "Experience 3 to 5 years",
    salary: "10000",
    city: "pune",
    state: "maharashtra",
    postedAt: "2024-01-10T14:30:00",
    openings: "3",
    applicants: "20",
    jobDescription: `1) Proficiency in React ,Node , Javascript/typescript, html, css tailwind css, Mysql/postgres Database
      2) Proficient knowledge of RDBMS Database >> MSSQL,
      3) Good Understanding of REST APIs
      4) Understanding of responsive design principles.`,
    requiredSkills: `Should have good communication skills. Should be able.
      to write code and connect with External as well as
      internal API`,
    roleName: "React JS Developer",
    industryType: "IT Services & Consulting",
    department: "Engineeering - Software & QA",
    employmentType: "full time, pemanent",
    roleCategory: "Software Developement",
    education: "B.Sc in Computers, B.Tech/B.E. in Computers, BCA in Computers",
    keySkills: [
      "Mern Stack",
      "Mean Stack",
      "Full Stack",
      "Node JS",
      "ReacJS",
      "My SQL",
      "Mongo DB",
    ],
  },
  {
    jobId: "JOB002",
    image: "team-02",
    location: "Pune",
    title: "Looking For Node JS Developer (WFO - Pune)",
    subTitle: "freshers candidates Only",
    totalExperience: "Experience 3 to 5 years",
    relevantExperience: "Experience 3 to 5 years",
    salary: "10000",
    city: "pune",
    state: "maharashtra",
    postedAt: "2024-01-10T14:30:00",
    openings: "3",
    applicants: "20",
    jobDescription: `1) Proficiency in React ,Node , Javascript/typescript, html, css tailwind css, Mysql/postgres Database
      2) Proficient knowledge of RDBMS Database >> MSSQL,
      3) Good Understanding of REST APIs
      4) Understanding of responsive design principles.`,
    requiredSkills: `Should have good communication skills. Should be able.
      to write code and connect with External as well as
      internal API`,
    roleName: "Node JS Developer",
    industryType: "IT Services & Consulting",
    department: "Engineeering - Software & QA",
    employmentType: "full time, pemanent",
    roleCategory: "Software Developement",
    education: "B.Sc in Computers, B.Tech/B.E. in Computers, BCA in Computers",
    keySkills: [
      "Mern Stack",
      "Mean Stack",
      "Full Stack",
      "Node JS",
      "ReacJS",
      "My SQL",
      "Mongo DB",
    ],
  },
  {
    jobId: "JOB003",
    image: "team-03",
    location: "Pune",
    title: "Looking For React Native Developer (WFO - Pune)",
    subTitle: "freshers candidates Only",
    totalExperience: "Experience 3 to 5 years",
    relevantExperience: "Experience 3 to 5 years",
    salary: "10000",
    city: "pune",
    state: "maharashtra",
    postedAt: "2024-01-10T14:30:00",
    openings: "3",
    applicants: "20",
    jobDescription: `1) Proficiency in React Native, Javascript/typescript, html, css
      2) Understanding of mobile app development principles
      3) Experience in API integration and debugging`,
    requiredSkills: `Should have good problem-solving skills. Ability to work in a team.`,
    roleName: "React Native Developer",
    industryType: "IT Services & Consulting",
    department: "Engineeering - Software & QA",
    employmentType: "full time, pemanent",
    roleCategory: "Software Developement",
    education: "B.Sc in Computers, B.Tech/B.E. in Computers, BCA in Computers",
    keySkills: [
      "React Native",
      "Javascript",
      "HTML",
      "CSS",
      "Mobile App Development",
      "API Integration",
    ],
  },
  // Continue adding the remaining roles...
  {
    jobId: "JOB004",
    image: "team-04",
    location: "Pune",
    title: "Looking For Flutter Developer (WFO - Pune)",
    subTitle: "freshers candidates Only",
    totalExperience: "Experience 3 to 5 years",
    relevantExperience: "Experience 3 to 5 years",
    salary: "10000",
    city: "pune",
    state: "maharashtra",
    postedAt: "2024-01-10T14:30:00",
    openings: "3",
    applicants: "20",
    jobDescription: `1) Proficiency in Dart, Flutter, and Object-Oriented Programming
      2) Experience in building responsive mobile applications
      3) Understanding of Flutter architecture`,
    requiredSkills: `Should have strong analytical skills. Ability to work independently.`,
    roleName: "Flutter Developer",
    industryType: "IT Services & Consulting",
    department: "Engineeering - Software & QA",
    employmentType: "full time, pemanent",
    roleCategory: "Software Developement",
    education: "B.Sc in Computers, B.Tech/B.E. in Computers, BCA in Computers",
    keySkills: [
      "Dart",
      "Flutter",
      "Mobile App Development",
      "Object-Oriented Programming",
    ],
  },
  {
    jobId: "JOB005",
    image: "team-05",
    location: "Pune",
    title: "Looking For AWS Architect (WFO - Pune)",
    subTitle: "freshers candidates Only",
    totalExperience: "Experience 3 to 5 years",
    relevantExperience: "Experience 3 to 5 years",
    salary: "10000",
    city: "pune",
    state: "maharashtra",
    postedAt: "2024-01-10T14:30:00",
    openings: "3",
    applicants: "20",
    jobDescription: `1) Proficiency in AWS services like EC2, S3, Lambda, etc.
      2) Experience in designing scalable and fault-tolerant systems
      3) Understanding of security best practices in AWS`,
    requiredSkills: `Should have excellent problem-solving skills and strong architectural abilities.`,
    roleName: "AWS Architect",
    industryType: "IT Services & Consulting",
    department: "Engineeering - Software & QA",
    employmentType: "full time, pemanent",
    roleCategory: "Software Developement",
    education: "B.Sc in Computers, B.Tech/B.E. in Computers, BCA in Computers",
    keySkills: [
      "AWS Services",
      "Scalable Systems",
      "Fault-tolerant Systems",
      "Security Best Practices",
    ],
  },
  {
    jobId: "JOB006",
    image: "team-06",
    location: "Pune",
    title: "Looking For GCP Developer (WFO - Pune)",
    subTitle: "freshers candidates Only",
    totalExperience: "Experience 3 to 5 years",
    relevantExperience: "Experience 3 to 5 years",
    salary: "10000",
    city: "pune",
    state: "maharashtra",
    postedAt: "2024-01-10T14:30:00",
    openings: "3",
    applicants: "20",
    jobDescription: `1) Proficiency in Google Cloud Platform services like Compute Engine, BigQuery, etc.
      2) Experience in designing and deploying scalable applications on GCP
      3) Understanding of GCP security and networking principles`,
    requiredSkills: `Should have strong problem-solving skills and a good understanding of GCP services.`,
    roleName: "GCP Developer",
    industryType: "IT Services & Consulting",
    department: "Engineeering - Software & QA",
    employmentType: "full time, pemanent",
    roleCategory: "Software Developement",
    education: "B.Sc in Computers, B.Tech/B.E. in Computers, BCA in Computers",
    keySkills: [
      "Google Cloud Platform Services",
      "Scalable Applications",
      "Security Principles",
      "Networking",
    ],
  },
  {
    jobId: "JOB007",
    image: "team-07",
    location: "Pune",
    title: "Looking For Python Developer (WFO - Pune)",
    subTitle: "freshers candidates Only",
    totalExperience: "Experience 3 to 5 years",
    relevantExperience: "Experience 3 to 5 years",
    salary: "10000",
    city: "pune",
    state: "maharashtra",
    postedAt: "2024-01-10T14:30:00",
    openings: "3",
    applicants: "20",
    jobDescription: `1) Proficiency in Python programming language
      2) Experience in web development using frameworks like Django or Flask
      3) Knowledge of database systems like MySQL, PostgreSQL`,
    requiredSkills: `Should have strong analytical skills and the ability to develop efficient code.`,
    roleName: "Python Developer",
    industryType: "IT Services & Consulting",
    department: "Engineeering - Software & QA",
    employmentType: "full time, pemanent",
    roleCategory: "Software Developement",
    education: "B.Sc in Computers, B.Tech/B.E. in Computers, BCA in Computers",
    keySkills: [
      "Python",
      "Django",
      "Flask",
      "Web Development",
      "Database Systems",
    ],
  },
  {
    jobId: "JOB008",
    image: "team-08",
    location: "Pune",
    title: "Looking For Dot Net Developer (WFO - Pune)",
    subTitle: "freshers candidates Only",
    totalExperience: "Experience 3 to 5 years",
    relevantExperience: "Experience 3 to 5 years",
    salary: "10000",
    city: "pune",
    state: "maharashtra",
    postedAt: "2024-01-10T14:30:00",
    openings: "3",
    applicants: "20",
    jobDescription: `1) Proficiency in C# and .NET framework
      2) Experience in developing applications using ASP.NET
      3) Understanding of database systems like SQL Server`,
    requiredSkills: `Should have good problem-solving skills and the ability to work in a team.`,
    roleName: "Dot Net Developer",
    industryType: "IT Services & Consulting",
    department: "Engineeering - Software & QA",
    employmentType: "full time, pemanent",
    roleCategory: "Software Developement",
    education: "B.Sc in Computers, B.Tech/B.E. in Computers, BCA in Computers",
    keySkills: [
      "C#",
      ".NET Framework",
      "ASP.NET",
      "SQL Server",
      "Database Systems",
    ],
  },
  {
    jobId: "JOB009",
    image: "team-09",
    location: "Pune",
    title: "Looking For PHP Developer (WFO - Pune)",
    subTitle: "freshers candidates Only",
    totalExperience: "Experience 3 to 5 years",
    relevantExperience: "Experience 3 to 5 years",
    salary: "10000",
    city: "pune",
    state: "maharashtra",
    postedAt: "2024-01-10T14:30:00",
    openings: "3",
    applicants: "20",
    jobDescription: `1) Proficiency in PHP programming language
      2) Experience in developing web applications using frameworks like Laravel or CodeIgniter
      3) Knowledge of MySQL or other database systems`,
    requiredSkills: `Should have good communication skills and the ability to work in a collaborative environment.`,
    roleName: "PHP Developer",
    industryType: "IT Services & Consulting",
    department: "Engineeering - Software & QA",
    employmentType: "full time, pemanent",
    roleCategory: "Software Developement",
    education: "B.Sc in Computers, B.Tech/B.E. in Computers, BCA in Computers",
    keySkills: [
      "PHP",
      "Laravel",
      "CodeIgniter",
      "Web Development",
      "MySQL",
      "Database Systems",
    ],
  },
  {
    jobId: "JOB010",
    image: "team-10",
    location: "Pune",
    title: "Looking For Javascript Developer (WFO - Pune)",
    subTitle: "freshers candidates Only",
    totalExperience: "Experience 3 to 5 years",
    relevantExperience: "Experience 3 to 5 years",
    salary: "10000",
    city: "pune",
    state: "maharashtra",
    postedAt: "2024-01-10T14:30:00",
    openings: "3",
    applicants: "20",
    jobDescription: `1) Proficiency in React ,Node , Javascript/typescript, html, css tailwind css, Mysql/postgres Database
      2) Proficient knowledge of RDBMS Database >> MSSQL,
      3) Good Understanding of REST APIs
      4) Understanding of responsive design principles.`,
    requiredSkills: `Should have good communication skills. Should be able.
      to write code and connect with External as well as
      internal API`,
    roleName: "Javascript Developer",
    industryType: "IT Services & Consulting",
    department: "Engineeering - Software & QA",
    employmentType: "full time, pemanent",
    roleCategory: "Software Developement",
    education: "B.Sc in Computers, B.Tech/B.E. in Computers, BCA in Computers",
    keySkills: [
      "Mern Stack",
      "Mean Stack",
      "Full Stack",
      "Node JS",
      "ReacJS",
      "My SQL",
      "Mongo DB",
    ],
  },
  {
    jobId: "JOB011",
    image: "team-11",
    location: "Pune",
    title: "Looking For Jquery Developer (WFO - Pune)",
    subTitle: "freshers candidates Only",
    totalExperience: "Experience 3 to 5 years",
    relevantExperience: "Experience 3 to 5 years",
    salary: "10000",
    city: "pune",
    state: "maharashtra",
    postedAt: "2024-01-10T14:30:00",
    openings: "3",
    applicants: "20",
    jobDescription: `1) Proficiency in React ,Node , Javascript/typescript, html, css tailwind css, Mysql/postgres Database
      2) Proficient knowledge of RDBMS Database >> MSSQL,
      3) Good Understanding of REST APIs
      4) Understanding of responsive design principles.`,
    requiredSkills: `Should have good communication skills. Should be able.
      to write code and connect with External as well as
      internal API`,
    roleName: "Jquery Developer",
    industryType: "IT Services & Consulting",
    department: "Engineeering - Software & QA",
    employmentType: "full time, pemanent",
    roleCategory: "Software Developement",
    education: "B.Sc in Computers, B.Tech/B.E. in Computers, BCA in Computers",
    keySkills: [
      "Mern Stack",
      "Mean Stack",
      "Full Stack",
      "Node JS",
      "ReacJS",
      "My SQL",
      "Mongo DB",
    ],
  },
];

export default dummyJobs;
