import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import Cabimg1 from "../stories/cabServices/img1.png";
import Cabimg2 from "../stories/cabServices/img2.png";
import Cabimg3 from "../stories/cabServices/img3.jpg";
import Cabimg4 from "../stories/cabServices/img4.jpg";
import Cabimg5 from "../stories/cabServices/img5.jpg";
import Cabimg6 from "../stories/cabServices/img6.png";

import aiImg1 from "../stories/ai/img1.jpg";
import aiImg2 from "../stories/ai/img2.jpg";
import aiImg3 from "../stories/ai/img3.jpg";
import aiImg4 from "../stories/ai/img4.jpg";
import aiImg5 from "../stories/ai/img5.jpg";
import aiImg6 from "../stories/ai/img6.jpg";

import dateImg1 from "../stories/datingApp/img1.jpg";
import dateImg2 from "../stories/datingApp/img2.jpg";
import dateImg3 from "../stories/datingApp/img3.jpg";
import dateImg4 from "../stories/datingApp/img4.jpg";
import dateImg5 from "../stories/datingApp/img5.jpg";
import dateImg6 from "../stories/datingApp/img6.jpg";

import eComImg1 from "../stories/eCom/img1.jpg";
import eComImg2 from "../stories/eCom/img2.jpg";
import eComImg3 from "../stories/eCom/img3.jpg";
import eComImg4 from "../stories/eCom/img4.jpg";
import eComImg5 from "../stories/eCom/img5.jpg";
import eComImg6 from "../stories/eCom/img6.jpg";

import healthCareImg1 from "../stories/healthCare/img1.jpg";
import healthCareImg2 from "../stories/healthCare/img2.jpg";
import healthCareImg3 from "../stories/healthCare/img3.jpg";
import healthCareImg4 from "../stories/healthCare/img4.jpg";
import healthCareImg5 from "../stories/healthCare/img5.jpg";
import healthCareImg6 from "../stories/healthCare/img6.jpg";

const StoriesLazy = React.lazy(() => import("react-insta-stories"));
const WithSeeMore = React.lazy(() =>
  import("react-insta-stories").then((module) => ({
    default: module.WithSeeMore,
  }))
);
const marginBottom = "mb--10";
const storyObj = {
  cabServices: {
    image1: Cabimg1,
    image2: Cabimg2,
    image3: Cabimg3,
    image4: Cabimg4,
    image5: Cabimg5,
    image6: Cabimg6,
    text1: "Real Time GPS Tracking",
    text2: "ETA for user to reach to location",
    text3: "Geo Fence based Pickups and notification",
    text4: "Driver app and Customer apps for separate purpose",
    text5: "Driver Rating & Reviews",
    text6: "Emergency Services or SOS",
  },
  ai: {
    image1: aiImg1,
    image2: aiImg2,
    image3: aiImg3,
    image4: aiImg4,
    image5: aiImg5,
    image6: aiImg6,
    text1:
      "Leverage localisation like multilingual support, auto region detection, currency",
    text2: "AI can automate routine and repetitive tasks, saving time",
    text3:
      "Chatbots can handle routine customer inquiries, providing instant responses ",
    text4: "Convert spoken language into written text, transcription services",
    text5: "Predicting demand, managing inventory efficiently",
    text6: "Detect patterns indicative of fraudulent activities in real-time",
  },
  datingApp: {
    image1: dateImg1,
    image2: dateImg2,
    image3: dateImg3,
    image4: dateImg4,
    image5: dateImg5,
    image6: dateImg6,
    text1: "Potential matches based on GeoLocation",
    text2: "Algorithm to analyse Interest and Preferences",
    text3: "In- App Messaging to communicate and connect",
    text4: "Photo Sharing with Privacy Management",
    text5: "Implement a Left- Right swiping feature in potential matches",
    text6: "Support users to browse profiles when offline",
  },
  eCom: {
    image1: eComImg1,
    image2: eComImg2,
    image3: eComImg3,
    image4: eComImg4,
    image5: eComImg5,
    image6: eComImg6,
    text1: "Sorting options based on relevance, price, popularity, etc.",
    text2: "Multiple payment options, including credit/debit cards.",
    text3:
      "Optimise the app for fast loading times to enhance user experience.",
    text4: "Streamlined processes for handling returns.",
    text5:
      "User profiles with order history, shipping addresses, and preferences.",
    text6: "Automated inventory tracking to prevent overselling",
  },
  healthCare: {
    image1: healthCareImg1,
    image2: healthCareImg2,
    image3: healthCareImg3,
    image4: healthCareImg4,
    image5: healthCareImg5,
    image6: healthCareImg6,
    text1: "Booking of  Doctors as per availability",
    text2: "Video conferencing for remote consultations",
    text3: "Biometric authentication (SHA256) for secure login",
    text4:
      "Medication information, dosage reminders, and potential interactions",
    text5: "Automated reminders for upcoming appointments",
    text6: "Diet tracking and nutritional information.",
  },
  mobileBasedTrackingApp: {
    image1: Cabimg1,
    image2: Cabimg2,
    image3: Cabimg3,
    image4: Cabimg4,
    image5: Cabimg5,
    image6: Cabimg6,
    text1: "Real Time GPS Tracking",
    text2: "ETA for user to reach to location",
    text3: "Geo Fence based Pickups and notification",
    text4: "Driver app and Customer apps for separate purpose",
    text5: "Driver Rating & Reviews",
    text6: "Emergency Services or SOS",
  },
};

function InstaStories() {
  return (
    <div className="App">
      <div className="stories">
        <Suspense fallback>
          <StoriesLazy
            preloadCount={3}
            loop
            keyboardNavigation
            defaultInterval={8000}
            stories={stories2}
            onStoryEnd={(s, st) => {}}
            onAllStoriesEnd={(s, st) => {}}
            onStoryStart={(s, st) => {}}
            onNext={() => {}}
            onPrevious={() => {}}
            storyContainerStyles={{ overflow: "hidden" }}
          />
        </Suspense>
      </div>
    </div>
  );
}

const Story1 = () => {
  const storyToRender = useSelector((state) => state.ui.currentStory);
  return (
    <div
      style={{
        ...contentStyle2,
        backgroundImage: `url(${storyObj[storyToRender]?.image1})`,
        backgroundSize: "cover",
      }}
    >
      <h4 className={marginBottom} style={{ color: "black" }}>
        {storyObj[storyToRender]?.text1}
      </h4>
    </div>
  );
};
const Story2 = () => {
  const storyToRender = useSelector((state) => state.ui.currentStory);
  return (
    <div
      style={{
        ...contentStyle2,

        backgroundImage: `url(${storyObj[storyToRender]?.image2})`,
      }}
    >
      <h4 className={marginBottom} style={{ color: "black" }}>
        {storyObj[storyToRender]?.text2}
      </h4>
    </div>
  );
};
const Story3 = () => {
  const storyToRender = useSelector((state) => state.ui.currentStory);
  return (
    <div
      style={{
        ...contentStyle2,

        backgroundImage: `url(${storyObj[storyToRender]?.image3})`,
      }}
    >
      <h4 className={marginBottom} style={{ color: "black" }}>
        {storyObj[storyToRender]?.text3}
      </h4>
    </div>
  );
};
const Story4 = () => {
  const storyToRender = useSelector((state) => state.ui.currentStory);
  return (
    <div
      style={{
        ...contentStyle2,

        backgroundImage: `url(${storyObj[storyToRender]?.image4})`,
      }}
    >
      <h4 className={marginBottom} style={{ color: "black" }}>
        {storyObj[storyToRender]?.text4}
      </h4>
    </div>
  );
};
const Story5 = () => {
  const storyToRender = useSelector((state) => state.ui.currentStory);
  return (
    <div
      style={{
        ...contentStyle2,

        backgroundImage: `url(${storyObj[storyToRender]?.image5})`,
      }}
    >
      <h4 className={marginBottom} style={{ color: "black" }}>
        {storyObj[storyToRender]?.text5}
      </h4>
    </div>
  );
};
const Story6 = () => {
  const storyToRender = useSelector((state) => state.ui.currentStory);
  return (
    <div
      style={{
        ...contentStyle2,

        backgroundImage: `url(${storyObj[storyToRender]?.image6})`,
      }}
    >
      <h4 className={marginBottom} style={{ color: "black" }}>
        {storyObj[storyToRender]?.text6}
      </h4>
    </div>
  );
};

const stories2 = [
  {
    content: Story1,
  },
  {
    content: Story2,
  },
  {
    content: Story3,
  },
  {
    content: Story4,
  },
  {
    content: Story5,
  },
  {
    content: Story6,
  },
];

const image = {
  display: "block",
  maxWidth: "100%",
  borderRadius: 4,
};

const code = {
  background: "#eee",
  padding: "5px 10px",
  borderRadius: "4px",
  color: "#333",
};

const contentStyle2 = {
  background: "#333",
  width: "100%",
  padding: 20,
  color: "white",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "center",
  // minHeight: "100vh", // Ensure the div covers the full viewport height
  backgroundSize: "contain",
  backgroundRepeat: "noRepeat",
};

export default InstaStories;
