import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({ title, description, keywords, link }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />

        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={link}></link>

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Helmet>
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
