import React from "react";
import { lazy, Suspense } from "react";
import { useRoutes } from "react-router-dom";
import Error from "../pages/Error";
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

const ServicesPage = Loader(lazy(() => import("../pages/ServicesPage")));
const OurProducts = Loader(
  lazy(() => import("../pages/productsPages/OurProducts"))
);
const AttendenceMonitoring = Loader(
  lazy(() => import("../pages/productsPages/AttendenceMonitoring"))
);
const HRManagement = Loader(
  lazy(() => import("../pages/productsPages/HRManagement"))
);
const FieldSalesManagement = Loader(
  lazy(() => import("../pages/productsPages/FieldSalesManagement"))
);
const IndustriesPage = Loader(lazy(() => import("../pages/IndustriesPage")));
const Home = Loader(lazy(() => import("../pages/Home")));
const Careers = Loader(lazy(() => import("../pages/Careers")));
const Contact = Loader(lazy(() => import("../pages/Contact")));
const Admin = Loader(lazy(() => import("../pages/Admin")));
const AboutUs = Loader(lazy(() => import("../pages/AboutUs")));
function Router() {
  let element = useRoutes([
    {
      path: `${process.env.PUBLIC_URL + "/"}`,
      element: <Home />,
    },
    { path: `${process.env.PUBLIC_URL + "/careers"}`, element: <Careers /> },
    { path: `${process.env.PUBLIC_URL + "/contact"}`, element: <Contact /> },
    {
      path: `${process.env.PUBLIC_URL + "/secretadminstemmone"}`,
      element: <Admin />,
    },
    { path: `${process.env.PUBLIC_URL + "/about"}`, element: <AboutUs /> },
    {
      path: `${process.env.PUBLIC_URL + "/industries/:pageNameParams"}`,
      element: <IndustriesPage />,
    },
    {
      path: `${process.env.PUBLIC_URL + "/services/:pageNameParams"}`,
      element: <ServicesPage />,
    },
    {
      path: `${process.env.PUBLIC_URL + "/products/ourProducts"}`,
      element: <OurProducts />,
    },
    {
      path: `${process.env.PUBLIC_URL + "/products/attendanceMonitoring"}`,
      element: <AttendenceMonitoring />,
    },
    {
      path: `${process.env.PUBLIC_URL + "/products/fieldSalesManagement"}`,
      element: <FieldSalesManagement />,
    },
    {
      path: `${process.env.PUBLIC_URL + "/products/hRManagement"}`,
      element: <HRManagement />,
    },

    { path: "*", element: <Error /> },
  ]);

  return element;
}

export default Router;
