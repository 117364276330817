import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dummyJobs from "../elements/jobDescription/dummyJobs";

const uiSlice = createSlice({
  name: "profileentication",

  initialState: {
    isAdmin: true,
    currentStory: "",
    showStories: false,

    adminShowJob: {
      id: "",
      job: {},
      window: false,
      form: false,
    },

    showJob: {
      id: "",
      job: {},
      window: false,
      form: false,
    },
    status: "",
    error: "",
  },

  reducers: {
    setShowStory(state, action) {
      state.showStories = !state.showStories;
      state.currentStory = action.payload;
    },

    setShowJob(state, action) {
      const { id, form, window, whichForm } = action.payload;

      if (id) {
        state.showJob.job = dummyJobs.find((job) => job.jobId === id);
      }
      if (window || window === false) {
        state.showJob.window = window;
      }
      if (form || form === false) {
        state.showJob.form = form;
      }
    },

    setAdminShowJob(state, action) {
      const { id, form, window, job } = action.payload;

      if (id) {
        state.adminShowJob.job = dummyJobs.find((job) => job.jobId === id);
      }
      if (window || window === false) {
        state.adminShowJob.window = window;
      }
      if (form || form === false) {
        state.adminShowJob.form = form;
      }
      if (job) {
        state.adminShowJob.job = job;
      }
    },
  },

  extraReducers(builder) {
    // get user profile data
    // user Dashboard Content
    // builder
    //   .addCase(getUserDashboardContent.pending, (state, action) => {
    //     state.status = "loading";
    //   })
    //   .addCase(getUserDashboardContent.fulfilled, (state, action) => {
    //     state.status = "succeeded";
    //     state.userDashboardContent = action.payload;
    //   })
    //   .addCase(getUserDashboardContent.rejected, (state, action) => {
    //     state.status = "failed";
    //     state.error = action.error.message;
    //   });
    // Adhar  verification
  },
});

export const { setShowStory, setAdminShowJob, setShowJob } = uiSlice.actions;
export default uiSlice.reducer;
