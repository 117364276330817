import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { Box, Chip, Grid } from "@mui/material";

import { setShowJob } from "../../redux/uiSlice";
import { setSelectedJob } from "../../redux/jobsSlice";

const Description = () => {
  const dispatch = useDispatch();
  const selectedJob = useSelector((state) => state.allJobs.selectedJob);

  const handleEditJob = (e, jobId) => {
    e.preventDefault();

    dispatch(setShowJob({ whichForm: "edit", form: true }));
  };

  useEffect(() => {}, [selectedJob]);
  return (
    <>
      {/* Start Single Timeline  */}
      <div className="rn-timeline-wrapper timeline-style-one position-relative">
        <div className="single-timeline ">
          <div className="single-content">
            <div className="inner">
              <Button
                onClick={() => {
                  dispatch(setSelectedJob(null));
                  dispatch(setShowJob({ window: false, form: false }));
                }}
                variant="danger"
              >
                X
              </Button>{" "}
              <div className="row row--30 align-items-center">
                <div
                  className=" order-lg-1 col-lg-5 mt_md--40 mt_sm--40"
                  style={{ paddingBottom: "20px" }}
                >
                  <div className="content">
                    <span className="date-of-timeline">
                      {` Job Posted : ${new Date(
                        selectedJob?.postedAt
                      ).toLocaleDateString()}`}
                    </span>

                    <h3 className="title">{selectedJob?.title}</h3>

                    <p className="description">{selectedJob?.subTitle}</p>

                    <div className="row row--30">
                      <div className="col-lg-6 col-md-6 col-12" key={0}>
                        <div className="working-list">
                          <h5 className="working-title">
                            {selectedJob?.relevantExperience}
                          </h5>

                          {/* <p>{data.stepDescription}</p> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12" key={0}>
                        <div className="working-list">
                          <h5 className="working-title">
                            Salay: {selectedJob?.salary}
                          </h5>

                          {/* <p>{data.stepDescription}</p> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12" key={0}>
                        <div className="working-list">
                          <h5 className="working-title">
                            Openings: {selectedJob?.openings}
                          </h5>

                          {/* <p>{data.stepDescription}</p> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12" key={0}>
                        <div className="working-list">
                          <h5 className="working-title">
                            Applicants: {selectedJob?.applicants}
                          </h5>

                          {/* <p>{data.stepDescription}</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="read-morebtn pt-5">
                      {
                        <Button
                          style={{ paddingBottom: "20px" }}
                          onClick={() => dispatch(setShowJob({ form: true }))}
                          className="btn-default btn-large round"
                        >
                          Apply Now
                        </Button>
                      }
                    </div>
                  </div>
                </div>
                <div className="order-1 p-sm-5 order-lg-2 col-lg-7 ">
                  <div className="thumbnail ">
                    <div className="rtl">
                      <div
                        className="card"
                        style={{ maxHeight: "550px", overflowY: "scroll" }}
                      >
                        <div className="card-body">
                          <h6 className="card-title d-flex justify-content-between">
                            {" "}
                            <b>
                              <span
                                style={{ fontSize: "20px" }}
                              >{`${selectedJob?.roleName}`}</span>
                            </b>
                            <span>
                              <b>Job ID :</b>
                              {`  ${selectedJob?.jobId}`}
                            </span>
                            {/* <span>{`Date : ${new Date(
                        ticketDescription.createdAt
                      ).toLocaleDateString("en-IN", {
                        timeZone: "Asia/Kolkata",
                      })}`}</span> */}
                          </h6>
                          <h7 className="card-title d-flex pt-0 justify-content-between">
                            <span>
                              {" "}
                              <b>Department :</b>
                              {`  ${selectedJob?.department} `}
                            </span>
                          </h7>
                          <h7 className="card-title d-flex pt-0 justify-content-between">
                            <span>
                              <b>Employment Type :</b>
                              {`  ${selectedJob?.employmentType} `}
                            </span>
                          </h7>
                          <h7 className="card-title d-flex pt-0 justify-content-between">
                            <span>
                              <b>Role Category :</b>
                              {` ${selectedJob?.roleCategory} `}
                            </span>
                          </h7>
                          <h7 className="card-title d-flex pt-0 justify-content-between">
                            <span>
                              <b> Education :</b>
                              {` ${selectedJob?.education} `}
                            </span>
                          </h7>
                          <div id="vmap_usa_en">
                            <h6 className="card-title d-flex justify-content-between pt-5">
                              <b>
                                {" "}
                                <span>{`Key Skills :`}</span>
                              </b>
                            </h6>
                            <Box sx={{ flexGrow: 1, alignItems: "center" }}>
                              <Grid
                                container
                                spacing={1}
                                columns={{ xs: 12, sm: 12, md: 12 }}
                              >
                                {selectedJob?.keySkills?.length &&
                                  selectedJob?.keySkills?.map((keySkill) => (
                                    <Grid item>
                                      {" "}
                                      <Chip
                                        label={`${keySkill}`}
                                        color="primary"
                                        variant="outlined"
                                        style={{ fontSize: "15px" }}
                                      />{" "}
                                    </Grid>
                                  ))}
                              </Grid>
                            </Box>
                          </div>
                          <div id="vmap_usa_en">
                            <h6 className="card-title d-flex justify-content-between pt-5">
                              <b>
                                {" "}
                                <span>{`Description :`}</span>
                              </b>
                            </h6>
                            <span>{`${selectedJob?.jobDescription}`}</span>
                          </div>

                          <div id="vmap_usa_en">
                            <h6 className="card-title d-flex justify-content-between pt-5">
                              <b>
                                {" "}
                                <span>{`Requirements :`}</span>
                              </b>
                            </h6>
                            <span>{`${selectedJob?.requiredSkills}`}</span>
                          </div>
                          <div id="vmap_usa_en">
                            <h6 className="card-title d-flex justify-content-between pt-3  "></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Single Timeline  */}
    </>
  );
};

export default Description;
