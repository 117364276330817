import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../elements/logo/Logo";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import { addDoc, collection } from "firebase/firestore";
import { firestore } from "../../firebase";
import config from "../../config";
const footerIntem = footerOne[0];
const footerIntemOne = footerOne[1];
const footerIntemTwo = footerOne[2];
const footerIntemThree = footerOne[3];
const footerIntemFour = footerOne[4];
const footerIntemFive = footerOne[5];
const indexOneLink = footerIntemOne.quicklink;
const indexTwoLink = footerIntemTwo.quicklink;
const indexThreeLink = footerIntemThree.quicklink;
const indexFourLink = footerIntemFour.quicklink;

const FooterOne = () => {
  const [result, showresult] = useState(false);
  const [contactData, setContactData] = useState({
    email: "",
  });
  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setContactData({ ...contactData, [name]: value });
  };
  const newsLetterRequests = collection(firestore, "newsLetterRequests");
  const submitData = async () => {
    // event.preventDefault();
    const { email } = contactData;

    if (email) {
      const res = fetch(config.NEWS_LETTER_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });

      if (res) {
        setContactData({
          email: "",
        });

        alert("Hurrah! You Subscribed");
      } else {
        alert("plz fill the data");
      }
    } else {
      alert("plz fill the data");
    }
  };

  return (
    <>
      <footer className="rn-footer footer-style-default">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="widget-menu-top">
                    <h4 className="title">{footerIntemThree.title}</h4>
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {indexThreeLink.map((data, index) => (
                          <li key={index}>
                            <Link to={`${data.url}`}>{data.text}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="widget-menu-top">
                    <h4 className="title">{footerIntemOne.title}</h4>
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {indexOneLink.map((data, index) => (
                          <li key={index}>
                            <Link to={`${data.url}`}>{data.text}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="widget-menu-top">
                    <h4 className="title">{"Products"}</h4>
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {indexTwoLink.map((data, index) => (
                          <li key={index}>
                            <Link to={`${data.url}`}>{data.text}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="sm-1 offset-lg-1 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="inner">
                  <ul className="footer-link link-hover">
                    {indexFourLink.map((data, index) => (
                      <h4 key={index}>
                        <Link style={{ fontSize: "18px" }} to={`${data.url}`}>
                          {data.text}
                        </Link>
                      </h4>
                    ))}
                  </ul>
                </div>

                <div className="rn-footer-widget  pl--10">
                  <div className="logo ">
                    <Logo
                      image={`${process.env.PUBLIC_URL}/images/logo/stemmOnec2.png`}
                      image2={`${process.env.PUBLIC_URL}/images/logo/stemmOnec2.png`}
                    />
                  </div>
                  <div className=" rn-footer-widget pt--10">
                    <h4 className="title">{footerIntemFive.title}</h4>
                    <div className="inner">
                      {/* <h6 className="subtitle">{footerIntemFive.subtitle}</h6> */}
                      <form
                        onSubmit={submitData}
                        className="newsletter-form"
                        action="#"
                      >
                        <div className="form-group">
                          <input
                            type="email"
                            placeholder="Enter Your Email Here"
                            name="email"
                            value={contactData.email}
                            onChange={postUserData}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <button type="submit" className="btn-default">
                            Submit Now
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}
            </div>
          </div>
        </div>
      </footer>
      <ScrollTop />
    </>
  );
};

export default FooterOne;
