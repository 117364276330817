import { configureStore } from "@reduxjs/toolkit";
import uiSlice from "./uiSlice";
import pageSlice from "./pageSlice";
import jobsSlice from "./jobsSlice";

export const store = configureStore({
  reducer: {
    ui: uiSlice,
    page: pageSlice,
    allJobs: jobsSlice,
  },
});

export default store;
