import Modal from "react-bootstrap/Modal";
import InstaStories from "./InstaStories";
import { useSelector, useDispatch } from "react-redux";
import { setShowStory } from "../../redux/uiSlice";

function InstaStoryModal() {
  const dispatch = useDispatch();
  const rshow = useSelector((state) => state.ui.showStories);

  return (
    <>
      <Modal
        show={rshow}
        className="d-flex justify-content-center"
        fullscreen={false}
        onHide={() => dispatch(setShowStory())}
      >
        <InstaStories />
      </Modal>
    </>
  );
}

export default InstaStoryModal;
