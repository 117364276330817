import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dummyJobs from "../elements/jobDescription/dummyJobs";
import axios from "axios";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../firebase";
const jobs = collection(firestore, "jobs");
const jobApplications = collection(firestore, "applyjob");

export const getAllApplications = createAsyncThunk(
  "getAllApplications",
  async () => {
    return new Promise((resolve, reject) => {
      const fetchedJobs = [];

      const unsubscribe = onSnapshot(
        jobApplications,
        (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            fetchedJobs.push({ ...doc.data(), id: doc.id });
          });

          // Unsubscribe to stop listening for changes
          unsubscribe();

          resolve(fetchedJobs);
        },
        (error) => {
          // Unsubscribe in case of an error
          unsubscribe();
          reject(error);
        }
      );
    });
  }
);

export const getAllJobs = createAsyncThunk("getAllJobs", async () => {
  return new Promise((resolve, reject) => {
    const fetchedJobs = [];

    const unsubscribe = onSnapshot(
      jobs,
      (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          fetchedJobs.push({ ...doc.data(), id: doc.id });
        });

        // Unsubscribe to stop listening for changes
        unsubscribe();

        resolve(fetchedJobs);
      },
      (error) => {
        // Unsubscribe in case of an error
        unsubscribe();
        reject(error);
      }
    );
  });
});

export const deleteJob = createAsyncThunk("deleteJob", async (jobId) => {
  const jobDoc = doc(firestore, "jobs", jobId);

  try {
    await deleteDoc(jobDoc);
    return jobId; // Return the deleted jobId on successful deletion
  } catch (error) {
    throw error; // Throw the error to be handled in the rejection case
  }
});

export const updateJob = createAsyncThunk(
  "updateJob",
  async ({ jobId, newJobData }) => {
    const jobDoc = doc(firestore, "jobs", jobId);

    try {
      await updateDoc(jobDoc, newJobData);
      return { jobId, newJobData }; // Return the updated jobId and new data on successful update
    } catch (error) {
      throw error; // Throw the error to be handled in the rejection case
    }
  }
);

const jobsSlice = createSlice({
  name: "profileentication",

  initialState: {
    allJobs: [],
    allApplications: [],
    selectedJob: null,
    deletedJobId: null,
    status: "",
    error: "",
  },

  reducers: {
    setSelectedJob(state, action) {
      if (action.payload == null) {
        state.selectedJob = null;
      } else {
        state.selectedJob = state.allJobs.find(
          (job) => job.id === action.payload
        );
      }
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getAllApplications.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllApplications.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allApplications = action.payload;
      })
      .addCase(getAllApplications.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(getAllJobs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allJobs = action.payload;
      })
      .addCase(getAllJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(deleteJob.pending, (state) => {
        // Handle the pending state if needed
      })
      .addCase(deleteJob.fulfilled, (state, action) => {
        // Remove the deleted job from your state, if applicable
        state.status = "succeeded";
        state.deletedJobId = action.payload;
      })
      .addCase(deleteJob.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setSelectedJob } = jobsSlice.actions;
export default jobsSlice.reducer;

function addDummyData() {
  const promises = dummyJobs.map(async (dummyJob) => {
    try {
      const docRef = await addDoc(jobs, dummyJob);

      return docRef;
    } catch (error) {
      console.error("Error adding document: ", error);
      throw error; // Rethrow the error to stop Promise.all if there's an error
    }
  });

  // Wait for all promises to resolve
  return Promise.all(promises);
}
function addingdummy() {
  addDummyData()
    .then(() => {})
    .catch((error) => {
      console.error("Error adding documents: ", error);
    });
}
// addingdummy();
