import React, { useEffect, useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { storage, firestore } from "../../firebase";
import { Button } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

import { setShowJob } from "../../redux/uiSlice";
import { v4 } from "uuid";
import { setSelectedJob } from "../../redux/jobsSlice";

const ApplicationForm = () => {
  const selectedJob = useSelector((state) => state.allJobs.selectedJob);

  const dispatch = useDispatch();
  const showJob = useSelector((state) => state.ui.showJob);
  const [fileUpload, setFileUpload] = useState(null);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    roleName: selectedJob?.roleName,
    relevantExperience: "",
    previousCTC: "",
    startDate: "",
    interviewDate: "",
    coverLetter: "",
    resumeUrl: "",
  });

  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUserData({ ...userData, [name]: value });
  };
  const styleObj = {
    border: "2px solid #ced4da",
    borderRadius: "2px",
    outline: "none",
    fontSize: "15px",
  };

  const uploadFile = (event) => {
    event.preventDefault();
    if (fileUpload == null) return;

    const fileId = v4();
    const fileRef = ref(storage, `files/${fileId}-${fileUpload.name}`);

    // Upload the file
    uploadBytes(fileRef, fileUpload).then((snapshot) => {
      // Get the download URL and update the state
      getDownloadURL(snapshot.ref).then((url) => {
        if (url) {
          setUserData({ ...userData, resumeUrl: url });
        }
      });
    });
  };

  useEffect(() => {
    if (userData.resumeUrl !== "") {
      submitData();
    }
  }, [userData]);

  const submitData = async () => {
    // event.preventDefault();
    const {
      firstName,
      lastName,
      phone,
      email,
      roleName,
      relevantExperience,
      previousCTC,
      startDate,
      interviewDate,
      coverLetter,
      resumeUrl,
    } = userData;

    if (
      firstName &&
      lastName &&
      phone &&
      email &&
      roleName &&
      relevantExperience &&
      previousCTC &&
      startDate &&
      interviewDate &&
      coverLetter &&
      resumeUrl
    ) {
      const res = fetch(
        "https://us-central1-react-website-ba066.cloudfunctions.net/app/applyjob",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstName,
            lastName,
            phone,
            email,
            roleName,
            relevantExperience,
            previousCTC,
            startDate,
            interviewDate,
            coverLetter,
            resumeUrl,
          }),
        }
      );

      if (res) {
        setUserData({
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          roleName: "",
          relevantExperience: "",
          previousCTC: "",
          startDate: "",
          interviewDate: "",
          coverLetter: "",
          resumeUrl: "",
        });
        setFileUpload(null);
        alert("Application Submited");
      } else {
        alert("plz fill the data");
      }
    } else {
      alert("plz fill the data");
    }
  };

  return (
    <>
      {/* Start Single Timeline  */}
      <div className="rn-timeline-wrapper timeline-style-one position-relative">
        <div className="single-timeline ">
          <div className="single-content">
            <div className="inner">
              <Button
                onClick={() => {
                  dispatch(setSelectedJob(null));
                  dispatch(setShowJob({ window: false, form: false }));
                }}
                variant="danger"
              >
                X
              </Button>{" "}
              <div className="row row--30 align-items-center">
                <div
                  className=" order-lg-1 col-lg-5 mt_md--40 mt_sm--40"
                  style={{ paddingBottom: "20px" }}
                >
                  <div className="content">
                    <span className="date-of-timeline">
                      {` Job Posted : ${new Date(
                        selectedJob?.postedAt
                      ).toLocaleDateString()}`}
                    </span>

                    <h3 className="title">{selectedJob?.title}</h3>

                    <p className="description">{selectedJob?.subTitle}</p>

                    <div className="row row--30">
                      <div className="col-lg-6 col-md-6 col-12" key={0}>
                        <div className="working-list">
                          <h5 className="working-title">
                            {selectedJob?.relevantExperience}
                          </h5>

                          {/* <p>{data.stepDescription}</p> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12" key={0}>
                        <div className="working-list">
                          <h5 className="working-title">
                            Salay: {selectedJob?.salary}
                          </h5>

                          {/* <p>{data.stepDescription}</p> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12" key={0}>
                        <div className="working-list">
                          <h5 className="working-title">
                            Openings: {selectedJob?.openings}
                          </h5>

                          {/* <p>{data.stepDescription}</p> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12" key={0}>
                        <div className="working-list">
                          <h5 className="working-title">
                            Applicants: {selectedJob?.applicants}
                          </h5>

                          {/* <p>{data.stepDescription}</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="read-morebtn pt-5">
                      <Button
                        onClick={() => dispatch(setShowJob({ form: false }))}
                        className="btn-default btn-large round "
                      >
                        Go Back
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="order-1 p-sm-5 order-lg-2 col-lg-7 ">
                  <div className="thumbnail ">
                    <div className="rtl">
                      <div
                        className="card"
                        style={{ maxHeight: "550px", overflowY: "scroll" }}
                      >
                        <div className="card-body">
                          <div style={{ minHeight: "20px" }}></div>
                          <h4 className="card-title d-flex justify-content-center">
                            Job Application Form
                          </h4>
                          <h7 className="card-title d-flex pt-0 justify-content-center">
                            Please Fill Out the Form Below to Submit Your Job
                            Application!
                          </h7>
                          <div style={{ minHeight: "20px" }}></div>
                          <form onSubmit={uploadFile}>
                            <div className="row">
                              <div className="col-md-6 ">
                                <label htmlFor="validationCustom01">
                                  First Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="firstName"
                                  value={userData.firstName}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                />
                              </div>
                              <div className="col-md-6 ">
                                <label htmlFor="validationCustom04">
                                  Last Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="lastName"
                                  value={userData.lastName}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                />
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-6">
                                <label htmlFor="validationCustom04">
                                  Phone Number
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="phone"
                                  value={userData.phone}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                />
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="validationCustom04">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  value={userData.email}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                />
                              </div>
                            </div>
                            <div style={{ minHeight: "50px" }}></div>
                            <div className="row mt-4">
                              <div className="col-md-6">
                                <label htmlFor="validationCustom04">
                                  Applied Position
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="appliedPosition"
                                  value={selectedJob?.roleName}
                                  //   onChange={handleChangeForm}
                                  disabled
                                  style={styleObj}
                                />
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-6 ">
                                <label htmlFor="validationCustom01">
                                  Relevant Experience
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="relevantExperience"
                                  value={userData.relevantExperience}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                />
                              </div>
                              <div className="col-md-6 ">
                                <label htmlFor="validationCustom04">
                                  Previous CTC
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="previousCTC"
                                  value={userData.previousCTC}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                />
                              </div>
                            </div>
                            <div style={{ minHeight: "50px" }}></div>
                            <div className="row mt-4">
                              <div className="col-md-6">
                                <label htmlFor="validationCustom04">
                                  Earliest Start Date
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="startDate"
                                  value={userData.startDate}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                />
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="validationCustom04">
                                  Prefered Interview Date
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Enter Email"
                                  name="interviewDate"
                                  value={userData.interviewDate}
                                  onChange={postUserData}
                                  style={styleObj}
                                  required
                                />
                              </div>
                            </div>
                            <div style={{ minHeight: "50px" }}></div>
                            <div className="row mt-4">
                              <div className="col-md-12">
                                <label htmlFor="validationCustom04">
                                  Cover Letter
                                </label>
                                <textarea
                                  className="form-control"
                                  name="coverLetter"
                                  value={userData.coverLetter}
                                  onChange={postUserData}
                                  style={{
                                    fontSize: "15px",
                                    minHeight: "70px",
                                    border: "2px solid #ced4da",
                                    borderRadius: "2px",
                                    outline: "none",
                                  }}
                                  required
                                />
                              </div>
                            </div>
                            <div style={{ minHeight: "10px" }}></div>
                            <div className="row mt-4">
                              <div className="col-md-6">
                                <div class="custom-file">
                                  <label
                                    class="custom-file-label"
                                    for="inputGroupFile04"
                                  >
                                    Upload Resume
                                  </label>
                                  <input
                                    type="file"
                                    accept=".pdf"
                                    onChange={(event) => {
                                      setFileUpload(event.target.files[0]);
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-12">
                                <div class="d-grid gap-2">
                                  <Button
                                    class="btn btn-primary btn-lg"
                                    type="submit"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Single Timeline  */}
    </>
  );
};

export default ApplicationForm;
