import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { Box, Chip, Grid, Paper } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { setAdminShowJob, setShowJob } from "../../redux/uiSlice";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import { useState } from "react";
import { deleteDoc, doc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { deleteJob, getAllJobs, setSelectedJob } from "../../redux/jobsSlice";
// const useStyles = makeStyles((theme) => ({
//   //Excel Data

//   modalPaper: {
//     position: "absolute",
//     top: "20%",
//     left: "50%",
//     transform: "translate(-50%, -20%)",
//     width: 350,
//     height: 100,
//     paddingLeft: "30px",
//   },
// }));

const AddDescription = () => {
  const dispatch = useDispatch();
  // const classes = useStyles();
  const selectedJob = useSelector((state) => state.allJobs.selectedJob);

  const handleDeleteJob = async (e, jobId) => {
    // e.preventDefault();
    // const jobDoc = doc(firestore, "jobs", jobId);
    // await deleteDoc(jobDoc);
    dispatch(deleteJob(jobId));
    dispatch(setSelectedJob(""));
    dispatch(setAdminShowJob({ window: false }));
    // dispatch(getAllJobs());
  };
  const handleEditJob = (e) => {
    e.preventDefault();
    dispatch(setAdminShowJob({ form: true }));
  };

  return (
    <>
      {/* Start Single Timeline  */}
      <div className="rn-timeline-wrapper timeline-style-one position-relative">
        <div className="single-timeline ">
          <div className="single-content">
            <div className="inner">
              <div className="row row--30 align-items-center">
                <div className=" order-lg-1 col-lg-5 mt_md--40 mt_sm--40">
                  <div className="content">
                    <span className="date-of-timeline">
                      {` Job Posted : ${new Date(
                        selectedJob?.postedAt
                      ).toLocaleDateString()}`}
                    </span>

                    <h3 className="title">{selectedJob?.title}</h3>

                    <p className="description">{selectedJob?.subTitle}</p>

                    <div className="row row--30">
                      <div className="col-lg-6 col-md-6 col-12" key={0}>
                        <div className="working-list">
                          <h5 className="working-title">
                            {selectedJob?.relevantExperience}
                          </h5>

                          {/* <p>{data.stepDescription}</p> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12" key={0}>
                        <div className="working-list">
                          <h5 className="working-title">
                            Salay: {selectedJob?.salary}
                          </h5>

                          {/* <p>{data.stepDescription}</p> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12" key={0}>
                        <div className="working-list">
                          <h5 className="working-title">
                            Openings: {selectedJob?.openings}
                          </h5>

                          {/* <p>{data.stepDescription}</p> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12" key={0}>
                        <div className="working-list">
                          <h5 className="working-title">
                            Applicants: {selectedJob?.applicants}
                          </h5>

                          {/* <p>{data.stepDescription}</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="read-morebtn pt-5">
                      <div
                        className="d-flex "
                        //   sx={{ fontSize: "20px" }}
                        align="center"
                      >
                        <button
                          onClick={(e) => {
                            handleEditJob(e);
                          }}
                          className="btn btn-sm btn-icon  me-2   float-left btn-primary"
                          data-toggle="tooltip"
                          data-placement="top"
                          title=""
                          data-original-title="Edit"
                        >
                          <FiEdit3
                            style={{
                              color: "white",
                              fontSize: "24px",
                              fontWeight: "bold",
                            }}
                          />
                        </button>

                        <button
                          className="btn btn-sm btn-icon   me-2  btn-danger"
                          data-toggle="tooltip"
                          data-placement="top"
                          title=""
                          data-original-title="Delete"
                          onClick={(e) => handleDeleteJob(e, selectedJob?.id)}
                        >
                          <FiTrash2
                            style={{
                              color: "white",
                              fontSize: "24px",
                              fontWeight: "bold",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="order-1 p-sm-5 order-lg-2 col-lg-7 ">
                  <div className="thumbnail ">
                    <div className="rtl">
                      <div
                        className="card"
                        style={{ maxHeight: "550px", overflowY: "scroll" }}
                      >
                        <div className="card-body">
                          <h6 className="card-title d-flex justify-content-between">
                            {" "}
                            <b>
                              <span
                                style={{ fontSize: "20px" }}
                              >{`${selectedJob?.roleName}`}</span>
                            </b>
                            <span>
                              <b>Job ID :</b>
                              {`  ${selectedJob?.jobId}`}
                            </span>
                            {/* <span>{`Date : ${new Date(
                        ticketDescription.createdAt
                      ).toLocaleDateString("en-IN", {
                        timeZone: "Asia/Kolkata",
                      })}`}</span> */}
                          </h6>
                          <h7 className="card-title d-flex pt-0 justify-content-between">
                            <span>
                              {" "}
                              <b>Department :</b>
                              {`  ${selectedJob?.department} `}
                            </span>
                          </h7>
                          <h7 className="card-title d-flex pt-0 justify-content-between">
                            <span>
                              <b>Employment Type :</b>
                              {`  ${selectedJob?.employmentType} `}
                            </span>
                          </h7>
                          <h7 className="card-title d-flex pt-0 justify-content-between">
                            <span>
                              <b>Role Category :</b>
                              {` ${selectedJob?.roleCategory} `}
                            </span>
                          </h7>
                          <h7 className="card-title d-flex pt-0 justify-content-between">
                            <span>
                              <b> Education :</b>
                              {` ${selectedJob?.education} `}
                            </span>
                          </h7>
                          <div id="vmap_usa_en">
                            <h6 className="card-title d-flex justify-content-between pt-5">
                              <b>
                                {" "}
                                <span>{`Key Skills :`}</span>
                              </b>
                            </h6>
                            <Box sx={{ flexGrow: 1, alignItems: "center" }}>
                              <Grid
                                container
                                spacing={1}
                                columns={{ xs: 12, sm: 12, md: 12 }}
                              >
                                {selectedJob?.keySkills?.map((keySkill) => (
                                  <Grid item>
                                    {" "}
                                    <Chip
                                      label={`${keySkill}`}
                                      color="primary"
                                      variant="outlined"
                                      style={{ fontSize: "15px" }}
                                    />{" "}
                                  </Grid>
                                ))}
                              </Grid>
                            </Box>
                          </div>
                          <div id="vmap_usa_en">
                            <h6 className="card-title d-flex justify-content-between pt-5">
                              <b>
                                {" "}
                                <span>{`Description :`}</span>
                              </b>
                            </h6>
                            <span>{`${selectedJob?.jobDescription}`}</span>
                          </div>

                          <div id="vmap_usa_en">
                            <h6 className="card-title d-flex justify-content-between pt-5">
                              <b>
                                {" "}
                                <span>{`Requirements :`}</span>
                              </b>
                            </h6>
                            <span>{`${selectedJob?.requiredSkills}`}</span>
                          </div>
                          <div id="vmap_usa_en">
                            <h6 className="card-title d-flex justify-content-between pt-3  "></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Single Timeline  */}
    </>
  );
};

export default AddDescription;
