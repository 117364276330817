import Modal from "react-bootstrap/Modal";

import { useSelector, useDispatch } from "react-redux";
import { setAdminShowJob } from "../../redux/uiSlice";
import JobAddDescription from "./JobAddDescription";
import AddOrEdit from "./AddOrEdit";
import { setSelectedJob } from "../../redux/jobsSlice";

function JobAddModal() {
  const dispatch = useDispatch();
  const adminShowJob = useSelector((state) => state.ui.adminShowJob);

  return (
    <>
      <Modal
        closeButton
        size="xl"
        show={adminShowJob.window}
        fullscreen={true}
        onHide={() => {
          dispatch(setSelectedJob(null));
          dispatch(
            setAdminShowJob({
              id: "",
              window: false,
              form: false,
              job: {},
            })
          );
        }}
      >
        {adminShowJob.form ? <AddOrEdit /> : <JobAddDescription />}
      </Modal>
    </>
  );
}

export default JobAddModal;
