import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setCurrentPage } from "../../redux/pageSlice";
import { FiMessageSquare } from "react-icons/fi";

const Nav = () => {
  const dispatch = useDispatch();
  return (
    <ul className="mainmenu">
      <li className="has-droupdown">
        <Link to="#">Industries</Link>
        <ul className="submenu">
          <li
            onClick={() => {
              dispatch(setCurrentPage("finance"));
            }}
          >
            <Link to="/industries/finance">Finance</Link>
          </li>
          <li
            onClick={() => {
              dispatch(setCurrentPage("education"));
            }}
          >
            <Link to="/industries/education">Education</Link>
          </li>
          <li
            onClick={() => {
              dispatch(setCurrentPage("healthCare"));
            }}
          >
            <Link to="/industries/healthCare">Health Care</Link>
          </li>
          <li
            onClick={() => {
              dispatch(setCurrentPage("mediaAndEntertainment"));
            }}
          >
            <Link to="/industries/mediaAndEntertainment">
              Media and Entertainment
            </Link>
          </li>
          <li
            onClick={() => {
              dispatch(setCurrentPage("logisticsAndDistribution"));
            }}
          >
            <Link
              to="/industries/logisticsAndDistribution
            "
            >
              Logistics And distribution
            </Link>
          </li>
          <li
            onClick={() => {
              dispatch(setCurrentPage("travelAndHospitality"));
            }}
          >
            <Link
              to="/industries/travelAndHospitality
            "
            >
              Travel And Hospitality
            </Link>
          </li>
          <li
            onClick={() => {
              dispatch(setCurrentPage("retailAndECommerce"));
            }}
          >
            <Link
              to="/industries/retailAndECommerce
            "
            >
              Retail And E-Commerce
            </Link>
          </li>
        </ul>
      </li>
      <li className="has-droupdown">
        <Link to="#">Services</Link>
        <ul className="submenu">
          <li
            onClick={() => {
              dispatch(setCurrentPage("iot"));
            }}
          >
            <Link to="/services/iot">IoT</Link>
          </li>
          <li
            onClick={() => {
              dispatch(setCurrentPage("edgeServerManagement"));
            }}
          >
            <Link to="/services/edgeServerManagement">
              Edge Server Management
            </Link>
          </li>
          <li
            onClick={() => {
              dispatch(setCurrentPage("aVIntigration"));
            }}
          >
            <Link to="/services/aVIntigration">AV Integration</Link>
          </li>
          <li
            onClick={() => {
              dispatch(setCurrentPage("boardRoomSolutions"));
            }}
          >
            <Link to="/services/boardRoomSolutions">Board Room Solutions</Link>
          </li>
          <li
            onClick={() => {
              dispatch(setCurrentPage("videoConferenceSolutions"));
            }}
          >
            <Link
              to="/services/videoConferenceSolutions
            "
            >
              Video Conference Solutions
            </Link>
          </li>
          <li
            onClick={() => {
              dispatch(setCurrentPage("customSoftwaredevelopement"));
            }}
          >
            <Link
              to="/services/customSoftwareSolution
            "
            >
              Custom Software Development
            </Link>
          </li>
          <li
            onClick={() => {
              dispatch(setCurrentPage("mobileApplication"));
            }}
          >
            <Link
              to="/services/mobileApplication
            "
            >
              Mobile Application
            </Link>
          </li>
          <li
            onClick={() => {
              dispatch(setCurrentPage("erp"));
            }}
          >
            <Link
              to="/services/erp
            "
            >
              ERP
            </Link>
          </li>
          <li
            onClick={() => {
              dispatch(setCurrentPage("uiux"));
            }}
          >
            <Link
              to="/services/uiux
            "
            >
              UI/UX
            </Link>
          </li>
        </ul>
      </li>
      <li className="has-droupdown">
        <Link to="#">Products</Link>

        <ul className="submenu">
          <li
          // onClick={() => {
          //   dispatch(setCurrentPage("iot"));
          // }}
          >
            <Link to="/products/ourProducts">Our Products</Link>
          </li>
          <li
          // onClick={() => {
          //   dispatch(setCurrentPage("edgeServerManagement"));
          // }}
          >
            <Link to="/products/attendanceMonitoring">
              Attendence Monitoring
            </Link>
          </li>
          <li>
            <Link to="/products/fieldSalesManagement">
              Field Sales Management
            </Link>
          </li>
          <li>
            <Link to="/products/hRManagement">HR Management</Link>
          </li>
        </ul>
      </li>

      <li>
        <Link to="/about">About</Link>
      </li>
      <li className="has-droupdown">
        <Link to="/careers">Careers</Link>
      </li>
      <li className="has-droupdown">
        <Link to="/contact">Contact</Link>
      </li>
      <li className="has-droupdown">
        <Link to="/contact">
          Let's Talk <span style={{ width: "5px" }}></span>
          <FiMessageSquare />
        </Link>
      </li>
    </ul>
  );
};
export default Nav;
